<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Purchase</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Purchase Order
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">
              Manage Purchase Order
            </h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div
        class="col-6 tx-right"
        v-if="checkSinglePermission('create_purchase_order')"
      >
        <router-link
          :to="{ name: 'new-purchase-order' }"
          class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
          tag="button"
          >New Order</router-link
        >
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-9 d-block mr-0 pr-0">
          <select
            v-model="filterParams.pageIndex"
            @change="selectTotalData"
            class="mr-2 mg-t-5"
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
          <select
            v-model="filterParams.branch"
            class="filter-input mr-2 mg-t-5"
            @change="selectBranch"
          >
            <option value>All Branch</option>
            <option
              v-for="(branch, index) in slipSettings.branches"
              :key="index"
              :value="branch.id"
            >
              {{ branch.name }}
            </option>
          </select>
          <SearchDayMonthComponent
            @clicked="monthDayClicked"
          ></SearchDayMonthComponent>
          <div class="d-inline-block mr-2">
            <label class="filter-lavel mb-0 mr-1 mg-t-5">From</label>
            <input
              type="date"
              v-model="filterParams.from_date"
              placeholder="From"
              class="filter-input mr-1 mg-t-5"
              id="from_date"
              @input="selectFromDate"
            />
            <label class="filter-lavel mb-0 mr-1 mg-t-5">To</label>
            <input
              type="date"
              v-model="filterParams.to_date"
              placeholder="To"
              class="filter-input mr-1 mg-t-5"
              id="to_date"
              @input="selectEndDate"
            />
          </div>
        </div>
        <div class="col-md-3 ml-0 pl-0 mg-t-5">
          <input
            type="text"
            v-model="filterParams.searched"
            class="float-right"
            placeholder="Search..."
            id="search_field"
            @keyup="searchedKeyword"
          />
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-10p">Date</th>
              <th class="wd-10p">Order ID</th>
              <th class="wd-25p">Supplier Name</th>
              <th class="wd-10p">Order Amt.</th>
              <th class="wd-10p">Advance Amt.</th>
              <th class="wd-10p">Payment Mode</th>
              <th class="wd-10p">Remaning Amt.</th>
              <th
                class="wd-10p text-center no-print"
                v-if="
                  checkIfAnyPermission([
                    'edit_purchase_order',
                    'view_purchase_order',
                    'cancel_purchase_order',
                    'checkout_purchase_order',
                  ])
                "
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody v-if="!loading && dataSets.length > 0">
            <tr v-for="(order, index) in dataSets" :key="index">
              <th scope="row" class="table-start-item">
                {{ pageSerialNo + index }}
              </th>
              <td>{{ order.created }}</td>
              <td>
                {{ order.prefix }}{{ order.purchase_order_no }}
                <span class="tx-danger font-s" v-if="order.status == 2"
                  >- Canceled</span
                >
                <span class="tx-success font-s" v-else-if="order.status == 1"
                  >- Completed</span
                >
              </td>
              <td>
                <span v-if="order.supplier">
                  {{ order.supplier.name }}
                </span>
              </td>
              <td>{{ parseDigitForSlip(order.total) }}</td>
              <td>{{ parseDigitForSlip(order.paid_amount) }}</td>
              <td>
                <span v-if="order.payment_methods">{{
                  order.payment_methods.title
                }}</span>
              </td>
              <td>{{ parseDigitForSlip(order.due_amount) }}</td>
              <td
                class="text-center no-print"
                style="display: flex"
                v-if="
                  checkIfAnyPermission([
                    'edit_purchase_order',
                    'view_purchase_order',
                    'cancel_purchase_order',
                    'checkout_purchase_order',
                  ])
                "
              >
                <a
                  href="javascript:;"
                  class="mr-3 tx-success"
                  title="View Slip"
                  @click="view('purchaseOrderSlip', order.id)"
                >
                  <i class="fas fa-eye"></i>
                </a>
                <a
                  v-if="
                    order.status == 0 &&
                    checkSinglePermission('checkout_purchase_order')
                  "
                  href="javascript:;"
                  class="mr-3 tx-info"
                  title="Order Checkout"
                  @click="$router.push(`/purchase/order/checkout/${order.id}`)"
                >
                  <i class="fas fa-shopping-cart" title="Order Checkout"></i>
                </a>
                <a
                  href="javascript:;"
                  class="mr-3"
                  @click="edit(order.id)"
                  v-if="
                    order.status == 0 &&
                    checkSinglePermission('edit_purchase_order')
                  "
                  title="Edit Purchase Order"
                >
                  <i class="fa fa-edit"></i>
                </a>
                <a href="javascript:;" v-if="order.status == 0">
                  <i
                    class="fa fa-ban tx-danger"
                    title="Cancel Order"
                    v-if="order.paid_amount == 0"
                    @click="cancelPurchaseOrder(order.id)"
                  ></i>
                  <i
                    class="fa fa-ban tx-danger"
                    title="Cancel Order"
                    v-else-if="
                      order.paid_amount > 0 &&
                      checkSinglePermission('cancel_purchase_order')
                    "
                    @click="cancelPurchaseOrderWithAdvance(order.id)"
                  ></i>
                </a>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <tr>
              <td colspan="11" style="text-align: center">
                {{ errorMessage }}
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!loading && dataSets.length == 0">
            <tr>
              <td colspan="11" style="text-align: center">No records found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="11" style="text-align: center">Loading.....</td>
            </tr>
          </tbody>
        </table>
        <Paginate
          v-model="page"
          :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
        />
        <PurchaseOrderSlip @printSlip="printSlip" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import PurchaseOrderSlip from "./PurchaseOrderSlip";
import Paginate from "vuejs-paginate";
import Services from "./Services/Service";
import SearchDayMonthComponent from "../../../shared/SearchDayMonthComponent";
import _ from "lodash";
export default {
  components: {
    PurchaseOrderSlip,
    Paginate,
    SearchDayMonthComponent,
  },
  data() {
    return {
      loading: true,
      error: false,
      totalPageCount: 0,
      pageReload: "",
      pageSerialNo: 1,
      errorMessage: "",
      page: 1,
      filterParams: {
        pageIndex: 10,
        branch: "",
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        from_date: "",
        to_date: "",
        searched: "",
        offset: 0,
      },
      offset: 0,
    };
  },
  computed: {
    ...mapGetters(["dataId", "eventMessage", "modalId"]),
    ...mapGetters("purchase", ["dataSets", "slipSettings"]),
  },
  beforeMount() {
    this.getFilteredData();
    this.pageReload = true;
    Services.ManagePurchaseOrder.getSlipPredefinedData()
      .then((response) => {
        this.$store.commit("purchase/setSlipSettings", response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    //month day component clicked
    monthDayClicked(value) {
      if (value) {
        this.filterParams.from_date = "";
        this.filterParams.to_date = "";
        this.filterParams.day = value.day;
        this.filterParams.month = value.month;
        this.filterParams.year = value.year;
      }
      //make api call
      this.getFilteredData();
    },
    // pagination clicked
    clickCallback: function (page) {
      if (page == 1) {
        this.pageSerialNo = 1;
      } else {
        this.pageSerialNo = (page - 1) * this.filterParams.pageIndex + 1;
      }
      this.offset = (page - 1) * this.filterParams.pageIndex;
      this.getFilteredDataWithoutDates();
    },
    selectTotalData() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectBranch() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectSalesType() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectFromDate() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectEndDate() {
      if (this.filterParams.from_date) {
        this.getFilteredDataWithoutDates();
      }
    },
    //################ Searched Keyword ##################
    searchedKeyword: _.debounce(function () {
      this.page = 1;
      this.offset = 0;
      this.pageSerialNo = 1;
      this.$store.commit("purchase/setItemSearchLoading", true);
      this.getFilteredDataWithoutDates();
    }, 500),

    getFilteredData() {
      this.loading = true;
      let params = {
        pageIndex: this.filterParams.pageIndex,
        branch: this.filterParams.branch,
        today: this.filterParams.day,
        month: this.filterParams.month + 1,
        year: this.filterParams.year,
        from_date: this.filterParams.from_date,
        to_date: this.filterParams.to_date,
        searched: this.filterParams.searched,
        page_reload: this.pageReload,
        offset: this.offset,
      };
      this.ajaxRequest(params);
    },
    // without today/month section
    getFilteredDataWithoutDates() {
      this.loading = true;
      let params = {
        pageIndex: this.filterParams.pageIndex,
        branch: this.filterParams.branch,
        today: "",
        month: "",
        year: this.filterParams.year,
        from_date: this.filterParams.from_date,
        to_date: this.filterParams.to_date,
        searched: this.filterParams.searched,
        page_reload: this.pageReload,
        offset: this.offset,
      };
      this.ajaxRequest(params);
    },
    ajaxRequest(params) {
      Services.ManagePurchaseOrder.getFilteredPurchaseOrders(params)
        .then((response) => {
          let value = response.data.data;
          this.$store.commit("purchase/setDataSets", value.data);
          this.totalPageCount = value.total_no_of_pages;
          this.$store.commit("purchase/setItemSearchLoading", false);
        })
        .catch((error) => {
          this.$store.commit("purchase/setItemSearchLoading", true);
          this.error = true;
          if (
            !Object.prototype.hasOwnProperty.call(
              error,
              "response.data.error_message"
            )
          ) {
            if (error.response.data.error_message == "Access Denied.") {
              this.errorMessage = "Access Denied !!!";
              // this.$router.push({ name: "access-denied" });
            } else {
              this.errorMessage = "Error fetching data from the server.";
            }
          }
        })
        .finally(() => {
          this.loading = false;
          this.$store.commit("purchase/setItemSearchLoading", false);
        });
    },
    printSlip(id) {
      console.log(id);
      //once the modal is opened popup print window
      window.print();
    },
    view(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.commit("purchase/setSlipType", "purchaseOrder");
      this.$store.dispatch("modalWithMode", { id: modal, mode: "read" });
    },
    edit(id) {
      this.$store.commit("setDataId", id);
      this.$router.push(`/purchase/order/edit/${id}`);
    },
    cancelPurchaseOrder(id) {
      var remarks = "";
      this.$swal({
        title: "Do you really want to cancel this purchase order ?",
        html: `<label>Remarks: </label>
        <textarea id="textarea" class="swal2-input"></textarea>
       `,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Cancel it!",
        cancelButtonText: "No",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          // cancel the sales with remarks
          remarks = document.getElementById("textarea").value;
          return this.updatePaymentClosingOnceCanceled(id, remarks);
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // this.updatePaymentClosingOnceCanceled(id,remarks);
        }
      });
    },
    cancelPurchaseOrderWithAdvance(id) {
      var remarks = "";
      var checkboxValue = "";
      this.$swal({
        title: "Do you really want to cancel this purchase order ?",
        html: `<label>Remarks: </label>
          <textarea id="textarea" class="swal2-input"></textarea>
          <div class="refund-check">
          <input type="checkbox" id="chk"> Refund Advance
          </div>
        `,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Cancel it!",
        cancelButtonText: "No",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          // cancel the sales with remarks
          remarks = document.getElementById("textarea").value;
          checkboxValue = document.getElementById("chk").checked;
          return this.updatePaymentClosingOnceCanceled(
            id,
            remarks,
            checkboxValue
          );
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // this.updatePaymentClosingOnceCanceled(id,remarks,checkboxValue);
        }
      });
    },

    updatePaymentClosingOnceCanceled(id, remarks, checked = false) {
      return Services.ManagePurchaseOrder.cancelPurchaseOrder(id, {
        remarks: remarks,
        checked: checked,
      })
        .then((response) => {
          this.dataSets.forEach((item, index) => {
            if (item.id == id) {
              //2 = rejected
              this.dataSets[index]["status"] = 2;
              this.dataSets[index]["refunded"] = checked == true ? 1 : 0;
              this.dataSets[index]["remarks"] = remarks;
              this.dataSets[index]["canceled_date"] = this.today;
            }
          });
          if (response.status == 200) {
            let success = response.data.success_message;
            this.setNotification(success);
          }
          return response;
        })
        .catch((error) => {
          this.$swal.showValidationMessage(
            `Request failed: ${error.response.data.error_message}`
          );
          if (error.response.status == 500) {
            let err = error.response.data.error_message;
            this.setNotification(err);
          }
        });
    },
    setNotification(err) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", err);
    },
  },

  watch: {
    eventMessage(value) {
      console.log(value);
    },
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>
<style scoped>
</style>